body {
  margin: 0px;
  padding: 0px;
}
.header {
  height: 45px;
  position: fixed;
  top: 0;
}
#zoom {
  transform: translateY(-5vh);
}
.map {
  height: calc(100vh);
  z-index: 1;
}


