.dropdown-menu {
    background: rgba(0,100,0,.3);
    color: white;
    box-shadow: 1px 1px 10px black;
    text-shadow: 2px 2px 10px black;
    transform: translateY(5vh) translateX(-88vw);
    margin: auto;
    width: 95vw;
    height: 73vh;
  }
.chartSelect {
  background: rgba(0,100,0,.3);
  color: white;
  box-shadow: 1px 1px 10px black;
  text-shadow: 2px 2px 10px black;
}

.canvas-container {
  padding-left: 2vw;
  padding-bottom: 2vw;
  display: flex;
  float: left;
  margin: auto;
}
#riverFlow{
  margin-top: 5vh;
  width: 45vw;
  height: 50vh;
}
#creekFlow{
  margin-top: 5vh;
  width: 45vw;
  height: 50vh;
}
#combinedTemp{
  display: none;
  margin-top: 3vh;
  width: 90vw;
  height: 55vh;
}

#onlyRiverTemp, #onlyCreekTemp, #onlyRiverFlow, #onlyCreekFlow{
  margin-top: 3vh;
  width: 90vw;
  height: 55vh;
}
#onlyRiverFlowSmall, #onlyCreekFlowSmall, #onlyRiverTempSmall, #onlyCreekTempSmall{
  margin-top: 3vh;
  width: 90vw;
  height: 35vh;
  display: none;
}
.combined {
  display: block;
}
.dischargeRadio {
  display: none;
}
.labels {
  margin: auto;
  font-size: smaller;
  margin-top: 2vh;
}
/*
.chart1, .chart2 {
    width: 90vw;
    height: 70vh;
    margin: auto;
}*/
@media screen and (max-width: 1300px) {
  .dropdown-menu {
    transform: translateY(5vh) translateX(-86vw);
  }
}
@media screen and (max-width: 1000px) {
  .dischargeRadio{
    display: block;
  }
  .combined {
    display: none;
  }
}

@media screen and (max-width: 700px) {
  .dropdown-menu {
    transform: translateY(5vh) translateX(-83vw);
  }
}
@media screen and (max-width: 600px) {
  .dropdown-menu {
    transform: translateY(5vh) translateX(-80vw);
  }
}
@media screen and (max-width: 500px) {
  .dropdown-menu {
    transform: translateY(5vh) translateX(-79vw);
  }
  #onlyCreekFlow, #onlyRiverFlow, #onlyCreekTemp, #onlyRiverTemp {
    display: none;
  }
  #onlyRiverFlowSmall, #onlyCreekFlowSmall, #onlyRiverTempSmall, #onlyCreekTempSmall {
    display: block;
  }
}
@media screen and (max-width: 450px) {
  .dropdown-menu {
    transform: translateY(5vh) translateX(-76vw);
  }
}
@media screen and (max-width: 410px) {
  .dropdown-menu {
    transform: translateY(5vh) translateX(-74vw);
  }
}
@media screen and (max-width: 380px) {
  .dropdown-menu {
    transform: translateY(5vh) translateX(-73vw);
  }
}