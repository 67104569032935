
.navbar {
  left: 0;
  position: fixed;
  top: 0;
  z-index: 2;
  background: rgba(0, 100, 0, 0.3);
  width: 100%;
  box-shadow: 1px 1px 10px black;
  text-shadow: 2px 2px 10px black;
}

.h-box {
  border: solid white;
  padding: 0.5vh;
  padding-left: 2vh;
  padding-right: 1vh;
  box-sizing: border-box;
  box-shadow: 2px 2px 10px black;
}
a {
  color: white;
  text-decoration: underline;
}
/* Heading */
h1,
h2,
h3,
h4,
h5 {
  color: white;
}

/* Link */
.link {
  color: white;
  margin-right: 2vh;
}

.link:hover {
  color: gold;
  text-decoration: underline;
}

#basic-nav-dropdown{
  color: white;
  margin-right: 1vh;
}
/* Chart Link */

#basic-nav-dropdown:hover {
  color: gold;
  text-decoration: underline;
}

.h-box h2,
.h-box h3,
.h-box h4,
.h-box h5,
.med, 
.little,
.tiny {
  display: none;
}


@media screen and (max-width: 1200px) {
   .big, .h-box h1{
     display: none;
   }
   .med, .h-box h2{
     display: block;
   }
 }
 @media screen and (max-width: 975px) {
   .med, .h-box h2{
     display: none;
   }
   .little, .h-box h3 {
     display: block;
   }
 }
 @media screen and (max-width: 875px) {
   .navbar{
     display: block;
   }
   .h-box{
     text-align: center;
   }
 }
 @media screen and (max-width: 540px) {
   .h-box h3, .little{
     display: none;
   }
   .tiny, .h-box h4 {
     display: block;
     margin-top: .5vh;
   }
   .link {
     margin-right: 1vh;
   }
 }
 @media screen and (max-width: 300px) {
  .tiny {
    font-size: smaller;
  }
 }
/*
.drop {
  color: white;
  margin-left: 99%;
}

.small1,
.small2,
.small3,
.small4 {
  display: none;
}

.h-box h2 {
  display: none;
}
.drop1 {
  display: none;
}
.big2 {
  display: none;
}
.med {
  display: none;
}
.small {
  display: none;
}
@media screen and (min-width: 800px) and (max-width: 1199px) {
  .h-box h1 {
    display: none;
  }
  .h-box h2 {
    display: block;
  }
  .small1 {
    display: none;
  }
  .small2 {
    display: none;
  }
  .small3 {
    display: none;
  }
  .small4 {
    display: none;
  }
  .navbar {
    display: block;
  }
  .big1 {
    display: none;
  }
  .big2 {
    display: block;
  }
  .big {
    display: none;
  }
  .med {
    display: block;
  }
  .small {
    display: none;
  }
  .dropdown-menu {
    transform: none;
    padding: auto;
    margin: auto;
    height: 100%;
    width: 100%;
  }
}
@media screen and (min-width: 625px) and (max-width: 799px) {
  .h-box h1 {
    display: none;
  }
  .h-box h2 {
    display: block;
  }
  .small1 {
    display: none;
  }
  .small2 {
    display: none;
  }
  .small3 {
    display: none;
  }
  .small4 {
    display: none;
  }
  .navbar {
    display: block;
  }
  .big1 {
    display: none;
  }
  .big2 {
    display: none;
  }
  .big {
    display: none;
  }
  .med {
    display: block;
  }
  .small {
    display: none;
  }
  .dropdown-menu {
    transform: none;
    padding: auto;
    margin: auto;
    height: 100%;
    width: 100%;
  }
}

@media screen and (min-width: 550px) and (max-width: 624px) {
  .big1 {
    display: none;
  }
  .big2 {
    display: none;
  }
  .big {
    display: none;
  }
  .med {
    display: none;
  }
  .small {
    display: block;
  }
  .h-box h1 {
    display: none;
  }
  .h-box h2 {
    display: none;
  }
  .small1 {
    display: block;
  }
  .small2 {
    display: none;
  }
  .small3 {
    display: none;
  }
  .small4 {
    display: none;
  }
  .navbar {
    display: block;
  }
  .drop {
    display: block;
  }
  .dropdown-menu {
    transform: none;
    padding: auto;
    margin: auto;
    height: 100%;
    width: 100%;
  }
}

@media screen and (min-width: 400px) and (max-width: 549px) {
  .big1 {
    display: none;
  }
  .big2 {
    display: none;
  }
  .big {
    display: none;
  }
  .med {
    display: none;
  }
  .small {
    display: block;
  }
  .h-box h1 {
    display: none;
  }
  .h-box h2 {
    display: none;
  }
  .small1 {
    display: none;
  }
  .small2 {
    display: block;
  }
  .small3 {
    display: none;
  }
  .small4 {
    display: none;
  }
  .navbar {
    display: block;
  }
  .drop {
    display: block;
  }
  .dropdown-menu {
    transform: none;
    padding: auto;
    margin: auto;
    height: 100%;
    width: 100%;
  }
}
@media screen and (min-width: 325px) and (max-width: 399px) {
  .big1 {
    display: none;
  }
  .big2 {
    display: none;
  }
  .big {
    display: none;
  }
  .med {
    display: none;
  }
  .small {
    display: block;
  }
  .h-box h1 {
    display: none;
  }
  .h-box h2 {
    display: none;
  }
  .small1 {
    display: none;
  }
  .small2 {
    display: none;
  }
  .small3 {
    display: block;
  }
  .small4 {
    display: none;
  }
  .navbar {
    display: block;
  }
  .drop {
    display: block;
  }
  .dropdown-menu {
    transform: none;
    padding: auto;
    margin: auto;
    height: 100%;
    width: 100%;
  }
}
@media screen and (max-width: 324px) {
  .big1 {
    display: none;
  }
  .big2 {
    display: none;
  }
  .big {
    display: none;
  }
  .med {
    display: none;
  }
  .small {
    display: block;
  }
  .h-box h1 {
    display: none;
  }
  .h-box h2 {
    display: none;
  }
  .small1 {
    display: none;
  }
  .small2 {
    display: none;
  }
  .small3 {
    display: none;
  }
  .small4 {
    display: block;
  }
  .navbar {
    display: block;
  }
  .drop {
    display: block;
  }
  .dropdown-menu {
    transform: none;
    padding: auto;
    margin: auto;
    height: 100%;
    width: 100%;
  }
}*/
