.legend h4 {
  margin: 0 0 5px;
  color: #777;
}

.legend {
  text-align: left;
  line-height: 18px;
  color: #654321;
  padding: 8px 8px;
  font: 12px/12px Arial, Helvetica, sans-serif;
  background: wheat;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.legend i {
  width: 18px;
  height: 18px;
  float: left;
  margin-right: 8px;
  opacity: 0.7;
}

.leaflet-top.leaflet-right {
  transform: translate(-50%, 0%);
  top: 50%;
  transform: translate(0%, -50%);
}
